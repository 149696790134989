<template>
  <page-main
    title="Work responsibilities"
    nextText="On to your accomplishments!"
    buttonTabIndex="2"
    eventName="nav"
    @nav="nav('/WorkAccomplishments/' + key)"
    @save="save()"
  >
    <template v-slot:aside>
      What were your basic tasks and responsibilities while in this position{{ displayPosition ? ' ' + displayPosition : '' }}?
      <ul>
        <li>
          This section summarizes what you do on a day-to-day basis and can be
          considered a basic job description.
        </li>
        <li>
          Work responsibilities are not work
          accomplishments! We will go over your accomplishments in the next
          section.
        </li>
        <li>
          If you need help refining your responsibilities, use the AI
          optimization feature to help transform your writing.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <h3 class="font-weight-bold mb-4">Example #1:</h3>
        <ul class="font-italic">
          <li>
            Currently the Product Lead for Tech Strategy. Responsibilities as
            Product Lead include developing the strategy, business requirements,
            and implementation plans for Product A.
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="description"
            header="What were your work responsibilities (one to three, or else 1-3 sentences)"
            tabindex="1"
          >
            <template #footer>
              <ai-optimize-phrasing
                :phrasing="description"
                context="work-responsibility"
                @onAcceptPhrasing="acceptAiSuggestion"
              ></ai-optimize-phrasing>
            </template>
          </r-textarea>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import AiOptimizePhrasing from "../components/AiOptimizePhrasing";
import * as log from "../utility/logging/logger";
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as builder from "../store/modules/work_builder";
import * as disp from "@/utility/display.js";

// @ is an alias to /src
export default {
  name: "WorkResponsibilities",
  data: () => ({}),
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }
  },
  methods: {
    acceptAiSuggestion(newDescription) {
      log.logInformation(`new description [${newDescription}]`);
      this.setResponsibilitiesDescription({ description: newDescription });
      this.save();
    },
    save() {
      this.saveWork();
    },
    ...mapMutations("work", ["createEntry", "setResponsibilitiesDescription"]),
    ...mapActions("work", ["saveWork"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  components: {
    "ai-optimize-phrasing": AiOptimizePhrasing,
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist"]),
    ...mapFields("work", {
      key: "key",
      companyName: "workGeneral.companyName",
      description: "workResponsibilities.description",
      workRole: "workRole",
    }),
    displayPosition() {
      let role = builder.buildRolesDisplay(this.workRole)[0];
      if (disp.IsNullorWhitespace(role)) {
        return "";
      }
      return `(${role})`;
    },
    tips() {
      return [
        "Work responsibilities summarizes what you do on a day-to-day basis. Work responsibilities are not work accomplishments! We will go over accomplishments in the next page.",
        "If you need help with refining your work responsibilities, use the AI optimization feature to help you transform your writing. Beyond that, if you want to edit beyond that, you can do so by then toggling “Make your own edits”.",
      ];
    },
  },
};
</script>
